<template>
  <div class="typeList_wrap">
    <div
      class="typeList_item"
      v-for="item in typeData"
      :key="item.id"
      style="cursor: pointer"
      @click="setActiveId(item.id)"
      :style="activeId == item.id ? 'background:#0076FF;color:#fff' : ''"
    >
      {{ item.title }}
    </div>
  </div>
</template>

<script>
import { reactive, computed, onBeforeMount, onMounted } from "vue";
import { NewsTableStore, mainStore } from "@/store";
import { storeToRefs } from "pinia";
import { getNewsTable } from "@/api";
export default {
  setup() {
    const store = mainStore();
    const typeData = computed(() => storeToRefs(store).newsTypeData);
    const activeId = computed(() => storeToRefs(store).news_ActiveId);
    const setActiveId = async (value) => {
      store.setData("news_ActiveId", value);
      store.newsReloadTableData();
      // const res = await getNewsTable({
      //   current: store.news_currentPage || 1,
      //   size: store.news_size || 10,
      //   id: storeToRefs(store).news_ActiveId.value,
      // });
      // store.setData("news_total", res.data.total);
      // store.setData("news_data", res.data.records);
    };
    const data = reactive({
      typeData,
      activeId,
    });

    return {
      ...data,
      setActiveId,
    };
  },
};
</script>

<style scoped>
@import url("./typeList.css");
</style>

